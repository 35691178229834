const fetcher = async (url, options) => {
  const res =  await fetch(url, options)
  if (res.status >= 300) {
    let parsedError
    try {
      parsedError = await res.json()
    } catch (e) {
      throw new Error(res.status)
    }
    throw new Error(parsedError.code)
  }
  return res.json()
}

export default fetcher
