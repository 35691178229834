import { Container, Box, AspectRatio, Text, Stack, VStack, Heading, Flex } from '@chakra-ui/layout'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import useSWR from 'swr'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useSessionStorage } from 'react-use'

import fetcher from '../../../utils/fetcher'
// import BackgroundImage from '../../../components/BackgroundImage'
import FullpageVerticalCenter from '../../../components/FullpageVerticalCenter'
import { responsive } from '../../../components/ThemeProvider/theme'

import heart from './heart_yellow.png'

const fallbackImg = <StaticImage layout="fullWidth" src="./ani-first.png" alt="愛之語測驗" />
const Lottie = loadable.lib(() => import('lottie-web'), { fallback: fallbackImg })

const Loading = ({ lottie, onComplete }) => {
  const { data } = useSWR('/love-language-loading-2.json', fetcher)
  const ref = useRef()
  useEffect(() => {
    if (!ref.current.children.length) {
      setTimeout(() => {
        const ani = lottie.loadAnimation({
          container: ref.current, // the dom element
          renderer: 'canvas',
          loop: false,
          autoplay: true,
          animationData: data, // the animation data
          // onComplete: console.log
        });
        ani.onComplete = onComplete
      })
    }
  }, [data])
  return (
    <div ref={ref}>
      {!data && fallbackImg}
    </div>
  )
}

const types = [
  { id: 'lover', to: `/q/love-languages/lover/`, img: <StaticImage layout="fullWidth" src="./love.png" alt="愛情" /> },
  { id: 'friend', to: `/q/love-languages/friend/start/`, img: <StaticImage layout="fullWidth" src="./friend.png" alt="友情" /> },
]

const LoveLanguages = () => {
  const [loaded, setLoaded] = useSessionStorage('superquiz:love-language:loaded', false)
  const [quickLoaded, setQuickLoadd] = useState(false)
  const [iter, setIter] = useSessionStorage(`superquiz:love-languages:iter`)

  const { site: { siteMetadata: { siteUrl } } } = useStaticQuery(graphql`
    query SiteUrlQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  useEffect(() => {
    setIter(i => typeof iter === 'undefined' ? 0 : i + 1)
    if (loaded) setTimeout(() => setQuickLoadd(true), 1500)
  }, [])
  return (
    <Flex
      position="relative"
      backgroundImage={`url(${heart})`}
      backgroundRepeat="repeat"
      backgroundSize={responsive('75%', '25%')}
      backgroundPosition="50% 80%"
      bg={quickLoaded && 'love.yellow'}
      flex="1"
      transition="background 1s"
      willChange="background"
      flexDirection="column"
    >
      {/* <BackgroundImage
        position="absolute"
        top="0"
        right="0"
        left="0"
        bottom="0"
        src={heart}
        backgroundRepeat="repeat"
        backgroundSize={responsive('70%', '25%')}
        backgroundPosition="50% 80%"
      /> */}
      <FullpageVerticalCenter>
        <Container
          mt={responsive('0','-4em')}
          position="relative"
          overflow={responsive('hidden', 'visible')}
          maxWidth={responsive('container.sm', 'container.md')}
        >
          <Helmet>
            <title>愛之語線上測驗，和伴侶一起找到彼此的愛情語言</title>
            <meta property="og:image" content={`${siteUrl}/q/love-languages/share.png`} />
            <meta name="description" content="為什麼要做愛之語測驗？ 愛之語測驗是由蓋瑞．巧門博士 (Gary Chapman, Ph.D.) 所創設，他將情侶彼此之間的關係歸納總結成五種「愛的語言」：在愛情中，如果彼此都說一樣的愛情語言，雙方就能感覺到滿滿的愛。" />
          </Helmet>
          <VStack pt={responsive(0, '3em')} spacing="1em" px="1em">
            <Text
              letterSpacing="0.1em"
              color="love.titleOrange"
              textAlign="center"
              fontWeight="black"
              fontSize={responsive('1.25em', '1.375em')}
              transform={`translateY(${quickLoaded ? '0' : '2em'})`}
              transition="transform 1s, opacity 1s"
              opacity={1 * quickLoaded}
            >請選擇測驗類型</Text>
            <Stack pt={responsive(0, '1em')} width={responsive('100%', '120%')} flexDirection={responsive('column', 'row')} spacing={responsive("1.5em", '0')}>
              {types.map((t, i) => (
                <Link
                  key={t.id}
                  style={{
                    flex: 1,
                    pointerEvents: quickLoaded ? 'all' : 'none',
                    transform: `translateY(${quickLoaded ? '0' : '2em'})`,
                    transition: 'transform 1s, opacity 1s',
                    transitionDelay: `${250 * (1 + i)}ms`,
                    opacity: 1 * quickLoaded,
                  }}
                  to={t.to}
                >
                  <Box mx={responsive(0, '0.75rem')}>{t.img}</Box>
                </Link>
              ))}
            </Stack>
          </VStack>
          <Flex
            position="absolute"
            top={responsive('0', '0%')}
            bottom="0"
            left={responsive('0', '4em')}
            right={responsive('0', '4em')}
            opacity={1 * !quickLoaded}
            transition="opacity 1s"
            pointerEvents="none"
            willChange="opacity"
            flexDirection="column"
            justifyContent="center"
          >
            <AspectRatio ratio={842 / 763} ml={responsive('-42%', '-4%')} mr={responsive('-37%', '0')}>
              <Lottie>
                {({ default: lottie }) => (
                  <Loading
                    lottie={lottie}
                    onComplete={() => {
                      setLoaded(true)
                      setQuickLoadd(true)
                    }}
                  />
                )}
              </Lottie>
            </AspectRatio>
            <Box pos="absolute" top="55%" left="0" right="0" textAlign="center">
              <Heading as="h2" fontSize="1.25em" letterSpacing="widest" color="love.deepOrange">在感情裡，你是哪種動物？</Heading>
            </Box>
          </Flex>
        </Container>
      </FullpageVerticalCenter>
    </Flex>
  )
}

export default LoveLanguages
